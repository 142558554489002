import React, { Component } from "react";
import { t } from 'ttag';
import { toast } from "react-toastify";

import { fetchPost, fetchGet, setToken, handler, getLocale, unfocusAll } from "../util";
import { SubmitButton } from "./shared";

import { ReactComponent as CubLogo } from "../resources/logo_cub.svg";
import BusIng from "../resources/busIng.svg";
import BesIng from "../resources/besIng.svg";

type LoginProps = {
  updateHandler: (register?: boolean) => void,

}

type LoginState = {
  email?: string,
  password?: string,
  submitEnabled?: boolean,
  submitComelitEnabled?: boolean,
  showLogos?: boolean,
  country?: string,
}

/*async function loginComelit(email: string | undefined, password: string | undefined, loginComponent: LoginComponent)
{
  console.log(email, password);
  let body = JSON.stringify({ email: email, password: password });

  const res = await fetchGet('/api/auth/loginComelit');

  if (res.ok) {
    const t = await res.text();
    
  }
}*/

/*document.addEventListener("DOMContentLoaded", async (event: Event) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get('token');
  if (token) {
    console.log(token);
    var url = "/api/auth/callbackComelit/?token=" + token;
    const res = await fetchGet(encodeURI(url));
    const text = await res.text();
    
    if (text != "NoEsItalia") {
      setToken(text);
      window.location.href = "/index.html";
    }
    else {     
      //window.location.href = "/index2.html";    

      const container = document.createElement('div');
      container.style.display = "flex";
      container.style.flexDirection = "column";
      container.style.alignItems = "center";
      container.style.justifyContent = "center";
      container.style.width = "100%";

      const img1 = document.createElement('img');
      img1.src = BusIng;
      img1.alt = "Busing";
      img1.title = "Busing";
      img1.style.cursor = 'pointer';
      img1.style.width = '75%';
      img1.style.height = 'auto';
      img1.style.marginTop = '15%';
      img1.addEventListener('click', () => handleRedirect("https://cubic.ingeniumsl.com"));
      
      const img2 = document.createElement('img');
      img2.src = BesIng;
      img2.alt = "KNX";
      img2.title = "KNX";
      img2.style.cursor = 'pointer';
      img2.style.width = '75%';
      img2.style.height = 'auto';
      img2.style.marginTop = '15%';
      img2.addEventListener('click', () => handleRedirect("https://cubik.besknx.com"));

      container.appendChild(img1);
      container.appendChild(img2);

      document.body.appendChild(container); 
    }

  }
  else console.log("sintoken");
  
});*/

const handleRedirect = (protocol: string) => {
  var url = "";
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  if(protocol == "KNX")
  {
    window.location.replace("/index.html");
    return;
    
  }
  else
  {
    window.location.href =  "https://cubic.ingeniumsl.com"
    return;
  }

  
}

export default class LoginComponent extends Component<LoginProps, LoginState> {
  constructor(props: LoginProps) {
    super(props);
    //NEW LOGIN
    this.state = { email: '', password: '', submitEnabled: true, submitComelitEnabled: true, showLogos: false, country: '' };
  }

  async componentDidMount() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get('token');

    if (token) {
      console.log(token);
      var url = "/api/auth/callbackComelit/?token=" + token;
      const res = await fetchGet(encodeURI(url));
      const text = await res.text();
      console.log(text);
      if (!text.startsWith("NoEsItalia")) {
        setToken(text);
        window.sessionStorage.setItem("country", "IT");
        window.location.href = "/index.html";
      } else {
        this.setState({ showLogos: true }); 
        setToken(text.replaceAll("NoEsItalia", ""));
        window.sessionStorage.setItem("country", "noIT");
    
      }
    } else {
      console.log("sintoken");
    }
  }

  // Keep the state up to date with the fields values
  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const t = event.target;
    const value = t.type === "checkbox" ? t.checked : t.value;
    const name = t.name;

    this.setState({ [name]: value });
  }

  handleSubmit = async () => {
    unfocusAll();
    this.setState({ submitEnabled: false });

    const s = this.state;
    let body = JSON.stringify({ email: s.email, password: s.password });

    /*try{

      loginComelit(this.state.email, this.state.password, this);
    }
    catch(e){}*/

    try {
      const res = await fetchPost('/api/auth/login', body, false);
      if (res.ok) {
        const text = await res.clone().text(); // Clone because can't consume twice
        if (text.indexOf("__maintenance__") !== -1) {
          throw new Error(t`Webpage offline for maintenance. Please come back later`);
        }

        const json = await res.json();
        setToken(json.token);
        console.log(json.token);
        this.props.updateHandler();

      } else if (res.status === 401) {
        throw new Error(t`Invalid username or password`);
      } else {
        throw new Error(t`Error connecting to the server`);
      }
    } catch (e) {
      toast.error((e as any)?.message);
    } finally {
      this.setState({ submitEnabled: true });
    }
  }

  //NEW LOGIN
  handleSubmitComelit = async () => {
    unfocusAll();
    this.setState({ submitComelitEnabled: false });

    const s = this.state;
    try {
      var url = "https://cubik.comelitgroup.com/index.html";
      //const res = await fetchGet("https://account.comelitgroup.com/#/?callback=" + encodeURI(url));
      window.location.href = "https://account.comelitgroup.com/#/?callback=" + encodeURI(url)
      //console.log(res);
    } catch (e) {
      toast.error((e as any)?.message);
    } finally {
      this.setState({ submitComelitEnabled: true });
    }

  
  }


  render() {
    if (this.state.showLogos) {
      return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100vh", width: "100%" }}>
          <img
            src={BusIng}
            alt={"Busing"}
            title="Busing"
            style={{ cursor: 'pointer', width: '75%', height: 'auto', marginTop: '15%' }}
            onClick={() => handleRedirect("Busing")}
          />
          <img
            src={BesIng}
            alt={"KNX"}
            title="KNX"
            style={{ cursor: 'pointer', width: '75%', height: 'auto', marginTop: '15%' }}
            onClick={() => handleRedirect("KNX")}
          />
        </div>
      );
    }
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const ingLogin = urlParams.get('ingLogin');
    const token = urlParams.get('token');
    if(!ingLogin && !token)
    {
      var url = "https://cubik.comelitgroup.com/index.html";
      window.location.href = "https://account.comelitgroup.com/#/?callback=" + encodeURI(url)
      return;
    }
    else
    {  
      if(!ingLogin)
      {
        return(  
          <div className="card-body" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
            <CubLogo style={{height: '50%', width: '50%'}} className="pb-3 mb-3" />
          </div> 
        );
      }
      
      return (
        <div className="card-body">
          <CubLogo height="70px" className="pb-3 mb-3" />

          <form action="." onSubmit={handler(this.handleSubmit)} method="post">
            <div className="form-group">
              <input type="email" autoComplete="email" required className="form-control text-light input-field" name="email"
                id="loginMail" value={this.state.email} onChange={this.handleChange} placeholder={t`Email address`} />
            </div>

            <div className="form-group">
              <input type="password" required className="form-control text-light input-field" name="password"
                autoComplete="current-password" id="loginPass" placeholder={t`Password`}
                value={this.state.password} onChange={this.handleChange} />
            </div>

            <a href="#f" className="text-light d-block pt-1" onClick={handler(() => {
              const email = prompt(t`Please enter your email`);
              if (!email || !email.trim()) { return; }

              fetchPost("/api/auth/forgot", JSON.stringify({ email, lang: getLocale() }))
                .then(p => toast.success(t`An email has been sent with the steps to reset the password`))
            })}>
              {t`Forgot your password?`}
            </a>

            <div className="d-flex justify-content-between pt-3">
              <button type="button" className="btn btn-secondary"
                onClick={() => this.props.updateHandler(true)}>
                {t`Register`}
              </button>

              <SubmitButton text={t`Login`} loadingText={t`Loading...`}
                enabled={!!this.state.submitEnabled} className="bg-dark btn-outline-primary" />

              {/* NEW LOGIN */}            
            </div>
          </form>
          <form action="." onSubmit={handler(this.handleSubmitComelit)} method="post">
            <SubmitButton text={t`Iniciar sesión comelit`} loadingText={t`Loading...`}
                  enabled={!!this.state.submitComelitEnabled} className="bg-dark btn-outline-primary" /> 
          </form>
        </div>
      );
    }
  }

  
}

